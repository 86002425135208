<template>
  <div class="w-full card-slider mt-6">
    <div
      class="container pt-10"
      :style="{
        marginBottom: blok.overlap ? '-140px' : '',
      }"
    >
      <div
        v-html="$storyapi.richTextResolver.render(blok.blok_title)"
        class="mont mb-8 pt-6 text-2xl md:text-4xl text-brownish-grey"
      ></div>
      <div class="custom-controls">
        <button @click="prevSlide">
          <Icon
            class="icon mr-2"
            icon="charm:chevron-left"
            width="40"
            height="40"
          />
        </button>
        <button @click="nextSlide">
          <Icon
            class="icon"
            icon="charm:chevron-right"
            width="40"
            height="40"
          />
        </button>
      </div>
      <client-only>
        <vue-tiny-slider v-bind="tinysettings" ref="slidermobile">
          <div v-for="item in blok.blocks" :key="item.index">
            <CardSlide :blok="item" />
          </div>
        </vue-tiny-slider>
      </client-only>
    </div>
  </div>
</template>
<script>
import CardSlide from "./CardSlide.vue";
import { Icon } from "@iconify/vue2";

export default {
  name: "CardSlider",
  components: {
    CardSlide,
    Icon,
  },
  props: {
    blok: {
      type: Object,
      required: true,
      default: {},
    },
  },
  setup(props) {
    const tinysettings = {
      container: "#mouse-drag",
      mouseDrag: true,
      loop: true,
      controls: false,
      nav: false,
      "nav-container": false,
      items: 1.8,
      mouseDrag: true,
      // slideBy: "page",
      swipeAngle: false,
      speed: 400,
      edgePadding: 0,
      autoplayButton: false,
      autoplayButtonOutput: false,
      autoplay: true,
      gutter: 20,
      responsive: {
        480: {
          gutter: 0,
          items: 1,
          controls: true,
          autoplay: true,
          autoplayButton: false,
          autoplayButtonOutput: false,
        },
        481: {
          gutter: 20,
          items: 1.5,
          autoplay: false,
        },
        640: {
          gutter: 20,
          items: 2.2,
          autoplay: false,
        },
        768: {
          gutter: 30,
          items: 2.6,
          autoplay: false,
        },
        1024: {
          edgePadding: 20,
          gutter: 40,
          items: 2.6,
          autoplay: false,
        },
        1200: {
          gutter: 40,
          items: 3.6,
          autoplay: false,
        },
        2000: {
          gutter: 40,
          items: 3.6,
          autoplay: false,
        },
      },
    };
    return {
      tinysettings,
    };
  },
  methods: {
    prevSlide() {
      this.$refs.slidermobile.slider.goTo("prev");
    },
    nextSlide() {
      this.$refs.slidermobile.slider.goTo("next");
    },
  },
};
</script>
<style lang="scss" scoped>
.card-slider {
  background-color: #e8e8e8;
  min-height: 726px;
}
.container ::v-deep {
  #tns2-iw {
    margin: 0;
    @media only screen and (max-width: 480px) {
      margin: 0 -15px;
    }
  }
  #tns2 {
    padding: 0 -10px;
    .tns-item {
      @media only screen and (max-width: 480px) {
        padding: 0 15px;
      }
    }
  }
}
::v-deep .tns-controls {
  display: none;
}
.custom-controls {
  display: none;
  @media only screen and (max-width: 480px) {
    display: block;
  }
  margin-bottom: 15px;
}
.icon {
  color: #cd1619;
  font-weight: bold;
  border-radius: 50%;
  border: 1px solid #cd1619;
  width: 50px;
  height: 50px;
  @media only screen and (max-width: 1023px) {
    width: 40px;
    height: 40px;
  }
}
</style>
