<template>
  <div class="sb-btn-wrapper container" v-if="blok">
    <a
      :href="blok.button_link.url"
      :target="blok.button_link.target"
      class="btn"
      aria-label="Custom button link"
      >{{ blok.button_text }}</a
    >
  </div>
</template>

<script>
export default {
  props: {
    blok: {
      type: Object,
      required: true,
      default: {},
    },
  },
};
</script>

<style lang="scss" scoped>
.sb-btn-wrapper {
  @apply flex justify-center py-8;
}
</style>
