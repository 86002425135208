var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"contact-us-comp",class:{ container: _vm.container }},[(_vm.blok.description.content.length > 1)?_c('div',{staticClass:"left"},[_c('div',{staticClass:"description",domProps:{"innerHTML":_vm._s(_vm.$storyapi.richTextResolver.render(_vm.blok.description))}})]):_vm._e(),_vm._v(" "),_c('transition',{attrs:{"name":"sf-fade","mode":"in-out"}},[(_vm.showSendForm)?_c('div',{staticClass:"right"},[_c('h2',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t("Contact")))]),_vm._v(" "),_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',[_c('div',{staticClass:"form"},[_c('ValidationProvider',{attrs:{"name":"form.company","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"input-block"},[_c('div',{staticClass:"input-wrapper"},[(_vm.form.company.length < 1)?_c('label',{staticClass:"input-label",attrs:{"for":"company-input"}},[_vm._v(_vm._s(_vm.$t("Company name"))),_c('span',{staticClass:"asterisk"},[_vm._v(" *")])]):_vm._e(),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.company),expression:"form.company"}],attrs:{"id":"company-input","type":"text"},domProps:{"value":(_vm.form.company)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "company", $event.target.value)}}})]),_vm._v(" "),_c('span',{staticClass:"err-msg"},[_vm._v(_vm._s(errors[0]))])])]}}],null,true)}),_vm._v(" "),_c('ValidationProvider',{attrs:{"name":"form.name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"input-block"},[_c('div',{staticClass:"input-wrapper"},[(_vm.form.name.length < 1)?_c('label',{staticClass:"input-label",attrs:{"for":"name-input"}},[_vm._v(_vm._s(_vm.$t("Your name"))),_c('span',{staticClass:"asterisk"},[_vm._v(" *")])]):_vm._e(),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.name),expression:"form.name"}],attrs:{"id":"name-input","type":"text"},domProps:{"value":(_vm.form.name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "name", $event.target.value)}}})]),_vm._v(" "),_c('span',{staticClass:"err-msg"},[_vm._v(_vm._s(errors[0]))])])]}}],null,true)}),_vm._v(" "),_c('ValidationProvider',{attrs:{"name":"form.type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"select-wrapper"},[_c('div',{staticClass:"select-input",class:{ 'select-input-open': _vm.showDropdown },attrs:{"id":"select-wrap"},on:{"click":function($event){_vm.showDropdown = !_vm.showDropdown}}},[(_vm.form.type.length < 1 && !_vm.showDropdown)?_c('label',{staticClass:"input-label",attrs:{"for":"input-select"}},[_vm._v(_vm._s(_vm.$t("Company type"))),_c('span',{staticClass:"asterisk"},[_vm._v(" *")])]):_vm._e(),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.type),expression:"form.type"}],attrs:{"id":"input-select","type":"text","disabled":"","required":""},domProps:{"value":(_vm.form.type)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "type", $event.target.value)}}}),_vm._v(" "),_c('Icon',{class:{ 'rotate-icon': _vm.showDropdown },attrs:{"icon":"mdi:chevron-down"}})],1),_vm._v(" "),_c('span',{staticClass:"err-msg"},[_vm._v(_vm._s(errors[0]))]),_vm._v(" "),_c('transition',{attrs:{"name":"dropdown"}},[(_vm.showDropdown)?_c('div',{staticClass:"dropdown"},_vm._l((_vm.company_types),function(comp,index){return _c('div',{key:index + 'cmp',staticClass:"company",on:{"click":function($event){_vm.form.type = comp.type_value;
                        _vm.showDropdown = false;}}},[_c('span',[_vm._v(_vm._s(comp.type_value))])])}),0):_vm._e()])],1)]}}],null,true)}),_vm._v(" "),_c('ValidationProvider',{attrs:{"name":"form.email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('div',{staticClass:"input-block"},[_c('div',{staticClass:"input-wrapper"},[(_vm.form.email.length < 1)?_c('label',{staticClass:"input-label",attrs:{"for":"email-input"}},[_vm._v(_vm._s(_vm.$t("Email address"))),_c('span',{staticClass:"asterisk"},[_vm._v(" *")])]):_vm._e(),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.email),expression:"form.email"}],attrs:{"id":"email-input","type":"email"},domProps:{"value":(_vm.form.email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "email", $event.target.value)}}})]),_vm._v(" "),_c('span',{staticClass:"err-msg"},[_vm._v(_vm._s(errors[0]))])])]}}],null,true)}),_vm._v(" "),_c('ValidationProvider',{attrs:{"name":"form.message","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('div',{staticClass:"input-block"},[_c('div',{staticClass:"textarea-wrapper"},[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.message),expression:"form.message"}],attrs:{"cols":"30","rows":"10"},domProps:{"value":(_vm.form.message)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "message", $event.target.value)}}})]),_vm._v(" "),_c('span',{staticClass:"err-msg"},[_vm._v(_vm._s(errors[0]))])])]}}],null,true)}),_vm._v(" "),_c('button',{staticClass:"btn",on:{"click":function($event){$event.preventDefault();handleSubmit().then(_vm.sendForm())}}},[_vm._v("\n              "+_vm._s(_vm.$t("Send this form"))+"\n            ")])],1)])]}}],null,false,3675851249)})],1):_c('div',{staticClass:"thank-you"},[_c('h2',{staticClass:"font-mont"},[_vm._v(_vm._s(_vm.$t("Thank you for contacting us")))])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }