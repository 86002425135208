<template>
  <div class="privacy-policy-main container relative">
    <div class="font-mont text-center mt-20">
      <div
        class="title"
        v-html="$storyapi.richTextResolver.render(blok.title)"
      ></div>
      <div
        class="subtitle"
        v-html="$storyapi.richTextResolver.render(blok.subtitle)"
      ></div>
    </div>
    <div
      class="text-wrapper flex lg:my-20 my-14 relative"
      v-if="
        blok &&
        (blok.text_left.content[0].content ||
          blok.text_right.content[0].content)
      "
    >
      <div
        class="text flex-1"
        v-html="$storyapi.richTextResolver.render(blok.text_left)"
      ></div>
      <div
        class="text flex-1"
        v-html="$storyapi.richTextResolver.render(blok.text_right)"
      ></div>
    </div>
    <span class="show-more" @click="toggleText">{{
      showMore == true ? "show more" : "show less"
    }}</span>
  </div>
</template>
<script>
import { ref } from "@nuxtjs/composition-api";
export default {
  name: "PrivacyPolicyMain",
  props: {
    blok: {
      type: Object,
      required: true,
      default: {},
    },
  },
  setup() {
    const showMore = ref(true);

    return {
      showMore,
    };
  },
  methods: {
    toggleText: function () {
      this.showMore = !this.showMore;
      if (this.showMore == true) {
        document.querySelector(".text-wrapper").style.maxHeight = "700px";
      } else {
        document.querySelector(".text-wrapper").style.maxHeight = "fit-content";
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.privacy-policy-main {
  z-index: 10;
  .show-more {
    display: none;
  }
  @media only screen and (max-width: 769px) {
    .text-wrapper {
      flex-direction: column;
    }
  }
  @media only screen and (max-width: 481px) {
    margin-bottom: 8rem;
    .show-more {
      display: block;
      font: normal normal normal 18px Montserrat;
      color: #cd1619;
    }
  }
}
::v-deep .title {
  text-align: center;
  font: normal normal normal 35px Montserrat;
  color: #cd1619;
  margin-bottom: 40px;
  b {
    font: normal normal bold 35px Montserrat;
  }
}
::v-deep .subtitle {
  text-align: center;
  max-width: 700px;
  margin: auto;
  font: normal normal normal 20px "Franklin Gothic Book", sans-serif;
  line-height: 35px;
  color: #000000;
  b {
    font: normal normal bold 20px "Franklin Gothic Book", sans-serif;
  }
  @media only screen and (max-width: 481px) {
    text-align: left;
    max-width: 100%;
    font: normal normal normal 18px "Franklin Gothic Book", sans-serif;
  }
}
::v-deep .text {
  font: normal normal normal 18px "Franklin Gothic Book", sans-serif;
  color: #000000;
  b {
    font: normal normal bold 18px "Franklin Gothic Book", sans-serif;
  }
  p {
    padding-bottom: 30px;
  }
}
.text-wrapper {
  max-height: 500px;
  overflow: scroll;
  overflow-x: hidden;
  direction: rtl;
  .text {
    direction: ltr;
    @apply px-12 font-fgb;
  }
  @media only screen and (max-width: 481px) {
    overflow: hidden;
    max-height: 700px;
    margin-bottom: 0;
    .text {
      padding: 0;
    }
  }
}
::-webkit-scrollbar {
  width: 15px;
  height: 10px;
  padding: 2px;
}

::-webkit-scrollbar-track {
  border: 1px solid #cd1619;
  border-radius: 25px;
  background-color: #cd1619;
  margin-bottom: 180px;
}

::-webkit-scrollbar-thumb {
  background: white;
  border: 3px solid #cd1619;
  border-radius: 25px;
}
</style>
