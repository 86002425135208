<template>
  <div>
    <nuxt-link
      v-if="blok.link"
      :to="
        localePath(
          `/${
            blok.link.cached_url.startsWith('/')
              ? blok.link.cached_url.substring(4)
              : blok.link.cached_url
          }`
        )
      "
      draggable="false"
    >
      <div class="relative rounded-b-xl card-slide">
        <div class="relative">
          <nuxt-img
            quality="90"
            format="webp"
            placeholder
            :src="blok.image.filename + '/m/filters:format(webp)'"
            class="card-curved-img object-fit w-full"
            draggable="false"
            alt="slider"
            width="385"
            height="257"
            loading="lazy"
          />
          <span class="logo absolute w-16 h-16">
            <nuxt-img
              quality="90"
              format="webp"
              placeholder
              :src="blok.logo.filename + '/m/filters:format(webp)'"
              draggable="false"
              alt="slider logo"
            />
          </span>
        </div>
        <div class="mx-4 md:mx-6 py-4">
          <h3 class="mont font-bold text-xl">{{ blok.title }}</h3>
          <div
            v-html="$storyapi.richTextResolver.render(blok.text)"
            class="card-text py-2 font-fgb"
          />
        </div>
      </div>
    </nuxt-link>
    <div class="relative rounded-b-xl card-slide" v-else>
      <div class="relative">
        <nuxt-img
          quality="90"
          format="webp"
          placeholder
          :src="blok.image.filename + '/m/filters:format(webp)'"
          class="card-curved-img object-fit w-full"
          draggable="false"
          alt="slider"
          width="385"
          height="257"
          loading="lazy"
        />
        <span class="logo absolute w-16 h-16">
          <nuxt-img
            quality="90"
            format="webp"
            placeholder
            :src="blok.logo.filename + '/m/filters:format(webp)'"
            draggable="false"
            alt="slider logo"
          />
        </span>
      </div>
      <div class="mx-4 md:mx-6 py-4">
        <h3 class="mont font-bold text-xl">{{ blok.title }}</h3>

        <div
          v-html="$storyapi.richTextResolver.render(blok.text)"
          class="card-text py-2 font-fgb"
        />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "HeroSlide",
  props: {
    blok: {
      type: Object,
      required: true,
      default: {},
    },
  },
};
</script>
<style lang="scss" scoped>
.card-slide {
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  img {
    height: auto;
  }
  height: 590px;
}
.card-curved-img {
  display: inline-block;
  clip-path: ellipse(100% 90% at 49% 9%);
}
.logo {
  background-color: white;
  border-radius: 50%;
  right: 20px;
  bottom: -10px;
  padding: 15px;
  display: block;
  transform: rotate(-10deg);
  @include for-mobile {
    padding: 12px;
    width: 3rem;
    height: 3rem;
  }
  > img {
    display: block;
    width: 100%;
    height: 100%;
  }
}
.card-text {
  ::v-deep {
    p {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      overflow: hidden;
      -webkit-line-clamp: 10; /* Adjust the number of lines to your preference */
      word-wrap: break-word;
    }
  }
}
</style>
